import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import numeral from 'numeral';
import { FormattedMessage, injectIntl } from 'react-intl';
import Button from '@material-ui/core/Button';


const getAum = user => {
  if (get(user, 'assets_under_management') === null || get(user, 'assets_under_management') === 0) {
    return false;
  }
  return true;
};

const FinancialAttributes = ({ authorized, user, obfuscateView, classes, newUser, companyBusinessType, intl }) => {
  if (obfuscateView) {
    return (
      <Grid container style={{ marginLeft: 20 }}>
        <Grid item sm={1} />
        <Grid item sm={2} xs={4}>
          <div className={(classes.header, 'blurry-text')}>$$$$</div>
          <div className={classes.body}><FormattedMessage id="profile_page.gross_revenue"/></div>
        </Grid>
        <Grid item sm={2} xs={4}>
          <div className={(classes.header, 'blurry-text')}>$$$$</div>
          <div className={classes.body}><FormattedMessage id="profile_page.recurring"/></div>
        </Grid>
        <Grid item sm={2} xs={4}>
          <div className={(classes.header, 'blurry-text')}>$$$$</div>
          <div className={classes.body}><FormattedMessage id="profile_page.clients"/></div>
        </Grid>
        <Grid item sm={2} xs={4}>
          {getAum(user) && (
            <div>
              <div className={(classes.header, 'blurry-text')}>$$$$</div>
              <div className={classes.body}><FormattedMessage id="profile_page.aum_aua"/></div>
            </div>
          )}
          {!getAum(user) && (
            <div>
              <div className={(classes.header, 'blurry-text')}>$$$$</div>
              <div className={classes.body}><FormattedMessage id="profile_page.avg_client_age"/></div>
            </div>
          )}
        </Grid>
        <Grid item sm={2} xs={4}>
          <div className={(classes.header, 'blurry-text')}>$$$$</div>
          {(companyBusinessType === 'life' ||
            companyBusinessType === 'wealth' ||
            companyBusinessType === 'wealth_rj') && <div className={classes.body}><FormattedMessage id="profile_page.practicing"/></div>}
          {companyBusinessType === 'p_and_c' && <div className={classes.body}><FormattedMessage id="profile_page.in_business"/></div>}
        </Grid>
        <Grid item sm={1} />
      </Grid>
    );
  }
  if (user.is_student && user.company?.enabled_student_profiles) {
    return (
      <Grid container spacing={1} style={{ marginLeft: 20 }} justify="space-between">
        <Grid item sm={1} />
        <Grid item sm={2} xs={4}>
          <div className={classes.header}>
            <a href={user.student_url_resume} target="_blank"><i className="fas fa-file-pdf" style={{ color: 'red' }}></i></a>         
          </div> 
          <div className={classes.body}><FormattedMessage id="profile_page.resume"/></div>
        </Grid>
        <Grid item sm={2} xs={4}>
          <div className={classes.header}>
            {get(user, 'student_program_year', 0)}
          </div>
          <div className={classes.body}>{get(user, 'company.student_program_title')}</div>
        </Grid>
        <Grid item sm={1} />
      </Grid>
    );
  } else {
    return (
      <Grid container spacing={1} style={{ marginLeft: 20 }}>
        <Grid item sm={1} />
        <Grid item sm={2} xs={4}>
          {companyBusinessType === 'wealth_rj' && (
            <div className={classes.header}>
              {get(user, 'gross_production', 0) >= 1000000 ? (
                numeral(get(user, 'gross_production', 0)).format(intl.formatMessage({id: `profile_page.currency`})).replace('m', 'M')
              ) : (
                numeral(get(user, 'gross_production', 0)).format(intl.formatMessage({id: `profile_page.currencys`})).replace('m', 'M')
              )}
            </div>
          )}
          {companyBusinessType != 'wealth_rj' && (
            <div className={classes.header}>
              {get(user, 'annual_revenue', 0) >= 1000000 ? (
                numeral(get(user, 'annual_revenue', 0)).format(intl.formatMessage({id: `profile_page.currency`})).replace('m', 'M')
              ) : (
                numeral(get(user, 'annual_revenue', 0)).format(intl.formatMessage({id: `profile_page.currencys`})).replace('m', 'M')
              )}
            </div>
          )}
          <div className={classes.body}>{companyBusinessType === 'wealth_rj' ? <FormattedMessage id="profile_page.gross_production"/> : <FormattedMessage id="profile_page.gross_revenue"/> }</div>
        </Grid>
        <Grid item sm={2} xs={4}>
          <div className={classes.header}>
            {get(user, 'recurring_revenue', 0) >= 1000000 ? (
              numeral(get(user, 'recurring_revenue', 0)).format(intl.formatMessage({id: `profile_page.currency`})).replace('m', 'M')
            ) : (
              numeral(get(user, 'recurring_revenue', 0)).format(intl.formatMessage({id: `profile_page.currencys`})).replace('m', 'M')
            )}
          </div>
          <div className={classes.body}><FormattedMessage id="profile_page.recurring"/></div>
        </Grid>
        <Grid item sm={2} xs={4}>
          <div className={classes.header}>{get(user, 'number_of_clients') || 0}</div>
          <div className={classes.body}><FormattedMessage id="profile_page.clients"/></div>
        </Grid>
        <Grid item sm={2} xs={4}>
          {getAum(user) && (
            <div>
              <div className={classes.header}>
                {get(user, 'assets_under_management', 0) >= 1000000 ? (
                  numeral(get(user, 'assets_under_management', 0)).format(intl.formatMessage({id: `profile_page.currency`})).replace('m', 'M')
                ) : (
                  numeral(get(user, 'assets_under_management', 0)).format(intl.formatMessage({id: `profile_page.currencys`})).replace('m', 'M')
                )}
              </div>
              <div className={classes.body}><FormattedMessage id="profile_page.aum_aua"/></div>
            </div>
          )}
          {!getAum(user) && (
            <div>
              <div className={classes.header}>{get(user, 'avg_client_age') || '-'}</div>
              <div className={classes.body}><FormattedMessage id="profile_page.avg_client_age"/></div>
            </div>
          )}
        </Grid>
        <Grid item sm={2} xs={4}>
          <div className={classes.header}>
            {get(user, 'years_practicing') || 0} {get(user, 'years_practicing') === 1 ? <FormattedMessage id="profile_page.year"/> : <FormattedMessage id="profile_page.years"/>}
          </div>
          {(companyBusinessType === 'life' ||
            companyBusinessType === 'wealth' ||
            companyBusinessType === 'wealth_rj') && <div className={classes.body}><FormattedMessage id="profile_page.practicing"/></div>}
          {companyBusinessType === 'p_and_c' && <div className={classes.body}><FormattedMessage id="profile_page.in_business"/></div>}
          {!companyBusinessType && <div className={classes.body}><FormattedMessage id="profile_page.in_business"/></div>}
        </Grid>
        <Grid item sm={1} />
      </Grid>
    );
  }
};

const styles = {
  header : {
    fontFamily : 'Trebuchet MS, Arial, Helvetica, sans-serif',
    fontWeight : 'bold',
    fontSize   : 20,
  },
  body   : {
    fontFamily : 'Trebuchet MS, Arial, Helvetica, sans-serif',
    color      : '#5a5a5a',
  },
};

export default withStyles(styles)(injectIntl(FinancialAttributes));
