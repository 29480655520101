import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonIcon from '@material-ui/icons/Person';
import WorkIcon from '@material-ui/icons/Work';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import Checkbox from '@material-ui/core/Checkbox';
import SimpleInputField from '../SimpleTextField/SimpleTextField';
import * as EmailValidator from 'email-validator';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'react-intl';

const updateUserMutation = gql`
  mutation toggleTalentAcquisitionUser($userId: ID!, $talentAcquisitionUser: Boolean!) {
    toggleTalentAcquisitionUser(userId: $userId, talentAcquisitionUser: $talentAcquisitionUser)
  }
`;

const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    margin: 12,
  },
  paper: {
    padding: 12,
  },
  control: {
    padding: theme.spacing(2),
  },
  title: {
    fontSize: 24,
    margin: 12,
  },
  button: {
    margin: theme.spacing.unit,
    fontSize: '24px',
  },
  talentCell: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    minWidth: '200px',
  },
  actionCell: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
});

const validateEmailString = (email, allowEmpty = false) => {
  if (allowEmpty) {
    return !email || EmailValidator.validate(email);
  }
  return !EmailValidator.validate(email);
};

const PendingAuthorizedUser = ({
  pending_authorized_user,
  authorizeAction,
  authorizeTalentAction,
  action,
  deleteAction,
  updateAction,
  userClick,
  classes,
  intl,
}) => {
  const [isTalentAcquisitionUser, setIsTalentAcquisitionUser] = useState(pending_authorized_user.user?.talent_acquisition_user);
  const [toggleTalentAcquisitionUserMutation] = useMutation(updateUserMutation);

  const toggleTalentAcquisitionUser = async () => {
    setIsTalentAcquisitionUser(!isTalentAcquisitionUser);
    await toggleTalentAcquisitionUserMutation({
      variables: {
        userId: pending_authorized_user.user.id,
        talentAcquisitionUser: isTalentAcquisitionUser,
      },
    });
  }

  return (
    <TableRow>
      <TableCell>
        <SimpleInputField
          id="name"
          label={intl.formatMessage({ id: 'admin_inventory_user.full_name' })}
          data={pending_authorized_user}
          action={action}
          required
        />
      </TableCell>
      <TableCell>
        <SimpleInputField
          id="email"
          validator={validateEmailString(pending_authorized_user.email)}
          type="email"
          label={intl.formatMessage({ id: 'shared.email' })}
          data={pending_authorized_user}
          action={action}
          helperText={
            !validateEmailString(pending_authorized_user.email)
              ? ''
              : intl.formatMessage({ id: 'admin_inventory_user.enter_valid_email' })
          }
          required
        />
      </TableCell>
      <TableCell>
        <div className={classes.talentCell}>
          <Checkbox
            checked={isTalentAcquisitionUser}
            onChange={toggleTalentAcquisitionUser}
            color="primary"
          />
          <WorkIcon color={isTalentAcquisitionUser ? "primary" : "disabled"} />
          <span style={{ color: isTalentAcquisitionUser ? '#1976d2' : '#808080', fontWeight: 500 }}>
            Is External Talent?
          </span>
        </div>
      </TableCell>
      <TableCell>
        <div className={classes.actionCell}>
          <Tooltip title="View User">
            <IconButton
              color="primary"
              className={classes.button}
              onClick={() => userClick(pending_authorized_user)}
            >
              <PersonIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="Authorize">
            <IconButton
              color="primary"
              className={classes.button}
              onClick={() => authorizeAction(pending_authorized_user)}
            >
              {isTalentAcquisitionUser ? (
                <Icon>assignment_turned_in</Icon>
              ) : (
                <Icon>done</Icon>
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton 
              className={classes.button}
              onClick={() => deleteAction(pending_authorized_user)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </div>
      </TableCell>
    </TableRow>
  );
};

const PendingAuthorizedUserWithStyles = withStyles(styles)(PendingAuthorizedUser);
export default injectIntl(PendingAuthorizedUserWithStyles);