import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { toUpper, startCase } from 'lodash';
import LinearProgress from '@material-ui/core/LinearProgress';
import MUIDataTable from 'mui-datatables';
import moment from "moment";
import numeral from 'numeral';
import TableSortLabel from '@material-ui/core/TableSortLabel';
const theme = createMuiTheme({
  overrides : {
    MUIDataTable         : {
      responsiveScroll : {
        maxHeight : '700px !important',
      },
    },
    MUIDataTableBodyCell : {
      root : {
        width : '130px',
      },
    },
  },
});

const generateColumns = (motivationFilters, classes, print_view, catastrophic_planning_word) => { 
  let columns = [
    'Name',
    'Email',
    'Transition Goals',
    'Branch',
    'Division',
    'Region',
    'Manager',
    'Approval State',
    'Disapproved Reason',
    'Age',
    'Industry Length of Service',
    'Years with Company',
    'Joined Date',
    'Total AUM',
    'Gross Production',
    'DCF Valuation'
  ].map(c => formatHeader(c, '#fffff', classes));
  if (motivationFilters.user_motivation == 'succession') {
    columns = columns.concat(
      [ 
        'Deal Room Created',
        'Days Since',
        'Owner',
        'Status',
        'Matching', 
        'Invited', 
        'Reviewing Proposal', 
        'NDA', 
        'Negotiating' 
      ].map(c =>
        formatHeader(c, '#D5D8DC', classes)
      )
    );
  } else if (motivationFilters.user_motivation == 'growth') {
    columns = columns.concat(
      [ 
        'Created Date',
        'Days Since',
        'Status',
        'Suggested Matches',
        'Rolodex Matches',
        'Total Matches',
        'Matching', 
        'Reviewing', 
        'Proposal', 
        'NDA', 
        'Negotiating' 
      ].map(c => formatHeader(c, '#7FB3D5', classes))
    );
  }
  columns = columns.concat(
    [
      `${catastrophic_planning_word} Plan Preference`,
      `${catastrophic_planning_word} Plan Stage`,
      `${catastrophic_planning_word} Partners`,
    ].map(c => formatHeader(c, '#fffff', classes))
  );
  return columns;
};

const formatHeader = (column, color, classes) => {
  return {
    name    : column,
    options : {
      customHeadRender : (columnMeta, handleToggleColumn, sortOrder) => (
        <th
          onClick={() => handleToggleColumn(columnMeta.index)}
          className={classes.dealRoomHeaders}
          style={{
            background : color,
          }}>
          {columnMeta.name}
          <TableSortLabel active={sortOrder.name === columnMeta.name} direction={sortOrder.direction || 'asc'} />
        </th>
      ),
    },
  };
};

const formatCurrency = amount => {
  let result = amount >= 1000000 ? numeral(amount).format('($0,0a)') : numeral(amount).format('($0a)');
  return toUpper(result);
};

const getLegend = (motivationFilters, classes) => {
  if (motivationFilters.user_motivation == 'succession') {
    return (
      <Grid container style={{ marginBottom: '6px' }} justify="flex-end">
        <div className={classes.colorBox} style={{ background: '#D5D8DC' }} />
        <button className={classes.legendText}>- Succession Meeting Room</button>
      </Grid>
    );
  } else if (motivationFilters.user_motivation == 'growth') {
    return (
      <Grid container style={{ marginBottom: '6px' }} justify="flex-end">
        <div className={classes.colorBox} style={{ background: '#7FB3D5' }} />
        <button className={classes.legendText}>- Growth Meeting Room</button>
      </Grid>
    );
  }
  return <div />;
};

const getOwnerName = (deals) => {
  if (!deals || !deals.length) return '-';
  
  const ownerNames = deals
    .map(deal => get(deal, 'owner.name'))
    .filter(name => name !== undefined && name !== null)
    .join(', ');
    
  return ownerNames || '-';
};

const MotivationTable = ({
  inventory_users,
  motivationFilters,
  classes,
  loading,
  print_view,
  catastrophic_planning_word,
}) => {
  console.log('inventory_users', inventory_users);
  const data = (inventory_users || []).map(inv => {
    let row = [
      inv.name || '-',
      inv.email || '-',
      inv.transition_goals || '-',
      inv.branch || '-',
      inv.division || '-',
      inv.region || '-',
      inv.manager_name || '-',
      inv.approval_state || '-',
      get(inv, "user.disapproved_reason_comment") || '-',
      inv.age || '-',
      inv.industry_length_of_service || '-',
      inv.years_with_company || '-',
      inv.user.created_at ? new Date(inv.user.created_at).toLocaleDateString() : '-',
      formatCurrency(inv.total_assets),
      formatCurrency(inv.user_gross_production),
      formatCurrency(inv.dcf_valuation),
    ];
    if (motivationFilters.user_motivation == 'succession') {
      row = row.concat([ 
        inv.creation_date,
        inv.creation_date ? moment(inv.creation_date).fromNow() : '',
        get(inv, 'deals', []).length > 0 ? getOwnerName(inv.deals) : '-',
        inv.status,
        inv.matching,
        inv.invited,
        inv.reviewing_proposal,
        inv.nda_out_for_review,
        inv.negotiating,
      ]);
    } else if (motivationFilters.user_motivation == 'growth') {
      row = row.concat([ 
        inv.creation_date,
        inv.creation_date ? moment(inv.creation_date).fromNow() : '',
        inv.status,
        get(inv, "deal_interests_workflow_state_suggested_match_count") || 0,
        get(inv, "deal_interests_workflow_state_following_count") || 0,
        get(inv, "deal_interests_count") || 0, 
        inv.di_matching,
        inv.reviewing,
        inv.proposal,
        inv.nda,
        inv.di_negotiating,
       ]);
    }
    row = row.concat([ 
      startCase(inv.cat_plan_preference), 
      inv.cat_plan_stage, 
      inv.cat_partners 
    ]);

    return row;
  });

  const getRowsPerPage = () => {
    if (print_view) {
      return (inventory_users || []).length;
    }
    return 10;
  };

  if (loading) {
    return (
      <div className={classes.loadingCard}>
        <LinearProgress />
      </div>
    );
  }

  return (
    <MuiThemeProvider theme={theme}>
      {print_view && getLegend(motivationFilters, classes)}
      <MUIDataTable
        className={print_view ? classes.print : classes.table}
        data={data}
        columns={generateColumns(motivationFilters, classes, print_view, catastrophic_planning_word)}
        options={{
          filter             : false,
          print              : false,
          fixedHeader        : true,
          responsive         : 'standard',
          rowsPerPage        : getRowsPerPage(),
          rowsPerPageOptions : print_view ? [] : [ 10, 15, 100 ],
          customToolbar      : () => getLegend(motivationFilters, classes),
        }}
      />
    </MuiThemeProvider>
  );
};

const styles = {
  root            : {
    width     : '100%',
    overflowX : 'auto',
  },
  table           : {
    minWidth : 650,
  },
  print           : {
    width : 'fit-content',
  },
  container       : { display: 'flex', flexDirection: 'column', padding: 25 },
  menu            : { display: 'flex', width: '100%', alignItems: 'center', marginRight: 6 },
  datepicker      : { display: 'flex', flexDirection: 'row', alignItems: 'baseline' },
  bigNumber       : { fontSize: 50 },
  subHeader       : { fontWeight: 'normal', marginBottom: 0 },
  numberLabel     : { fontSize: 16 },
  selectatron     : { marginRight: 10 },
  formControl     : {
    margin   : 2,
    minWidth : 120,
  },
  loadingCard     : {
    border  : '1px #dadada solid',
    margin  : 12,
    padding : '42px 12px',
  },
  colorBox        : {
    width        : '55px',
    height       : '32px',
    borderRadius : '3px',
    display      : 'inline-block',
  },
  legendText      : {
    background : '#fff',
    border     : 'none',
    cursor     : 'default',
  },
  dealRoomHeaders : {
    padding  : '16px',
    top      : '0px',
    zIndex   : '100',
    position : 'sticky',
  },
};

MotivationTable.propTypes = {
  inventory_users            : PropTypes.array,
  motivationFilters          : PropTypes.object.isRequired,
  classes                    : PropTypes.object.isRequired,
  catastrophic_planning_word : PropTypes.string,
};

MotivationTable.defaultProps = {
  inventory_users            : [],
  catastrophic_planning_word : 'Catastrophic',
};

const mapStateToProps = state => ({
  motivationFilters          : state.motivationFilters,
  inventory_users            : state.inventory_users.inventory_users,
  inventory_user_count       : state.inventory_users.inventory_user_count,
  transition_goals           : state.inventory_users.transition_goals,
  print_view                 : state.inventory_users.print_view,
  catastrophic_planning_word : state.inventory_users.catastrophic_planning_word,
});

const MotivationTableWithStyles = withStyles(styles)(MotivationTable);
export default connect(mapStateToProps)(MotivationTableWithStyles);
