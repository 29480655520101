import React, { useEffect, useState } from 'react';
import Team from '../components/Team/Team';
import client from '../lib/apolloClient';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/client'; 
import { get } from 'lodash';

const GET_TEAM = gql`
  query Team($id: ID!) {
    team(id: $id) {
      id
      name
      teamHeadline
      teamDescription
      teamAgreement
      teamMembers {
        id
        user {
          id
          name
          email
          user_name
          profile_picture
          gross_production
          assets_under_management
          number_of_clients
          original_gross_production
          original_assets_under_management
          original_number_of_clients
          __typename
        }
        __typename
      }
      teamRequest {
        id
        user {
          id
          user_name
          company {
            id
            feature_types
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

const TeamContainer = ({ match }) => {
  const [team, setTeam] = useState(null);
  const { loading, error, data } = useQuery(GET_TEAM, {
    variables: { id: match.params.id },
    client,
  });

  if(loading) {
    return <div>Loading...</div>;
  }
  if(error) {
    return <div>{error.message}</div>;
  }
  if(data && !team) {
    setTeam(data.team);
  }
  return <Team team={team} setTeam={setTeam} />;
};

export default TeamContainer;
