import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

// TODO get current user
const getProfileType = ({
  showCreateDealButton,
  companyHasNewMessages,
  companyHasNetworking,
  user,
  freeToMessage,
  expressInterest,
  becomeAPartner,
  disabled,
  expressThroughManager,
  unsubscribed,
  rolodexlMessage,
}) => {
  if (user.is_student && user.company?.enabled_student_profiles) {
    return 'student';
  }
  if (rolodexlMessage) {
    return 'rolodexlMessage';
  }
  if (unsubscribed) {
    return 'unsubscribed';
  }
  if (showCreateDealButton) {
    return 'showCreateDealButton';
  }
  if (!companyHasNetworking || disabled) {
    return 'networkingDisabled';
  }
  if (expressInterest && expressThroughManager && !user.expressed_interest) {
    return 'obfusctaed_not_expressed_through_manager';
  }
  if (expressInterest && expressThroughManager && user.expressed_interest) {
    return 'expressed_interest';
  }
  if (expressInterest && !user.expressed_interest) {
    return 'obfusctaed_not_expressed';
  }
  if (expressInterest && user.expressed_interest) {
    return 'expressed_interest';
  }
  if (freeToMessage && companyHasNewMessages && companyHasNetworking) {
    return 'regular';
  }
  if (becomeAPartner && !user.expressed_interest) {
    return 'limited_not_expressed';
  }
  if (becomeAPartner && user.expressed_interest) {
    return 'limited_expressed';
  }
};

const StatusSection = ({
  showCreateDealButton,
  companyHasNewMessages,
  companyHasNetworking,
  user,
  obfuscateView,
  currentUser,
  currentCompanySellerWord,
  freeToMessage,
  expressInterest,
  becomeAPartner,
  disabled,
  expressThroughManager,
  unsubscribed,
  rolodexlMessage,
  dealRoomName,
}) => {
  switch (getProfileType({
    showCreateDealButton,
    companyHasNewMessages,
    companyHasNetworking,
    user,
    obfuscateView,
    currentUser,
    freeToMessage,
    expressInterest,
    becomeAPartner,
    disabled,
    expressThroughManager,
    unsubscribed,
    rolodexlMessage,
  })) {
    case 'rolodexlMessage':
      return (
        <div>
          <strong><FormattedMessage id="profile_page.status_section.strong_status"/></strong>
          <p>
            <FormattedMessage id="profile_page.status_section.you_have_an_open_deal_room"/> 
          </p>
        </div>
      );
    case 'unsubscribed':
      return (
        <div>
          <strong><FormattedMessage id="profile_page.status_section.strong_status"/></strong>
          <p>
            <FormattedMessage id="profile_page.status_section.in_order_to_communicate_with_users"/> 
          </p>
        </div>
      );
    case 'showCreateDealButton':
      return (
        <div>
          <strong><FormattedMessage id="profile_page.status_section.strong_status"/></strong>
          <p>
            <FormattedMessage id="profile_page.status_section.want_to_engage_with_profiles_like_this" values={{dealRoomName}}/> 
          </p>
        </div>
      );
    case 'regular':
      return (
        <div>
          <strong><FormattedMessage id="profile_page.status_section.strong_status"/></strong>
          <p>
            <FormattedMessage id="profile_page.status_section.free_to_message_with_this_user"/> 
          </p>
        </div>
      );
    case 'networkingDisabled':
      return (
        <div>
          <strong><FormattedMessage id="profile_page.status_section.strong_status"/></strong>
          <p>
            <FormattedMessage id="profile_page.status_section.review_the_contents_of_this_profile"/> 
          </p>
        </div>
      );
    case 'forward_expression':
      return (
        <div>
          <strong><FormattedMessage id="profile_page.status_section.strong_status"/></strong>
          <p>
            <FormattedMessage id="profile_page.status_section.by_clicking_the_button_above" values={{user_name: user.user_name}}/> 
          </p>
        </div>
      );
    case 'obfusctaed_not_expressed':
      return (
        <div>
          <strong><FormattedMessage id="profile_page.status_section.strong_status"/></strong>
          <p>
            <FormattedMessage id="profile_page.status_section.in_order_to_learn_more_about_this_profile"/> 
          </p>
        </div>
      );
    case 'obfusctaed_expressed':
      return (
        <div>
          <strong><FormattedMessage id="profile_page.status_section.strong_status"/></strong>
          <p>
            <FormattedMessage id="profile_page.status_section.youve_made_an_expression_of_interest" values={{user_name: user.user_name}}/>           
          </p>
        </div>
      );
    case 'expressed_interest':
      return (
        <div>
          <strong><FormattedMessage id="profile_page.status_section.strong_status"/></strong>
          <p>
            <FormattedMessage id="profile_page.status_section.youve_expressed_interest_in" values={{user_name: user.user_name}}/> 
            
          </p>
        </div>
      );
    case 'obfuscated':
      return (
        <div>
          <strong><FormattedMessage id="profile_page.status_section.strong_status"/></strong>
          <p className="blurry-text">
            <FormattedMessage id="profile_page.status_section.lorem_ipsum_dolor"/> 
          </p>
        </div>
      );
    case 'limited_expressed':
      return (
        <div>
          <strong><FormattedMessage id="profile_page.status_section.strong_status"/></strong>
          <p>
            <FormattedMessage id="profile_page.status_section.your_request_to_become_a_currentcompanysellerword" values={{currentCompanySellerWord, user_name: user.user_name}}/> 
            
          </p>
        </div>
      );
    case 'limited_not_expressed':
      return (
        <div>
          <strong><FormattedMessage id="profile_page.status_section.strong_status"/></strong>
          <p>
            <FormattedMessage id="profile_page.status_section.in_order_to_connect_with_user" values={{user_name: user.user_name, currentCompanySellerWord}}/> 
          </p>
        </div>
      );
    case 'obfusctaed_not_expressed_through_manager':
      return (
        <div>
          <strong><FormattedMessage id="profile_page.status_section.strong_status"/></strong>
          <p>
            <FormattedMessage id="profile_page.status_section.someone_from_our_team_will_review_your_request"/>     
          </p>
        </div>
      );
    case 'student':
      return (
        <div>
          <strong><FormattedMessage id="profile_page.status_section.strong_status"/></strong>
          <p>
            <FormattedMessage id="profile_page.status_section.student_status_message" values={{student_word: user.company?.student_word}}/>
          </p>
        </div>
      );
    default:
      return <div><FormattedMessage id="profile_page.status_section.wrong_stage"/></div>;
  }
};

StatusSection.propTypes = {
  user                     : PropTypes.object.isRequired,
  obfuscateView            : PropTypes.bool.isRequired,
  companyHasNewMessages    : PropTypes.bool.isRequired,
  companyHasNetworking     : PropTypes.bool.isRequired,
  currentCompanySellerWord : PropTypes.bool.isRequired,
  freeToMessage            : PropTypes.bool.isRequired,
  expressInterest          : PropTypes.bool.isRequired,
  becomeAPartner           : PropTypes.bool.isRequired,
  disabled                 : PropTypes.bool.isRequired,
  expressThroughManager    : PropTypes.bool.isRequired,
  currentUser              : PropTypes.object.isRequired,
};

const styles = {
  button : {
    textTransform : 'capitalize',
    color         : '#1396e2',
    textAlign     : 'left',
  },
};

export default withStyles(styles)(StatusSection);
