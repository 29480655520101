import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { findIndex, remove, get, debounce } from 'lodash';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import CurrencyInput from 'react-currency-input-field';
import numeral from 'numeral';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import InputSlider from '../../InputSlider/InputSlider';
import WarningText from '../../WarningText/WarningText';
import { spotlightElement } from '../../../lib/findBobUtils';
import TransitionGoalSelector from './TransitionGoalSelector';
import CatastrophicPlanningPreference from './CatastrophicPlanningPreference';
import YearsInBusinessDropDown from './YearsInBusinessDropDown';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { sanitizeNumber, getMotivation, intlCurrencyConfig } from '../../../lib/findBobUtils';
import { STEP_NUMBERS } from '../SignUpWizard';
import PeProductSummaryReportContainer from '../../../containers/PeProductSummaryReportContainer';
import styled, { css } from 'styled-components';
import LoadingButtonWrapper from '../../LoadingButtonWrapper/LoadingButtonWrapper';
import { withTheme } from '@material-ui/core/styles';

const styles = theme => ({
  formGroup: {
    border: '1px solid #dadada',
    padding: '12px',
    marginTop: '6px',
    borderRadius: '2px',
  },
  groupTitle: {
    paddingLeft: '6px',
  },
  toolTip: {
    color: '#1396e2',
  },
  headline: {
    fontSize: 18,
    fontWeight: 'lighter',
    marginBottom: 20,
    color: '#333',
  },
  rjpeBackgroundColor: {
    backgroundColor: '#002949',
    '&:hover': {
      background: '#424949',
    },
  },
  ppiBackgroundColor: {
    backgroundColor: '#7399c6',
    '&:hover': {
      background: '#949ca',
    },
  },
  productionSummary: {
    marginTop: 25,
  },
});

class PracticeInformation extends React.Component {
  state = { error: null, avg_client_age: this.props.avg_client_age, assets_under_management: this.props.assets_under_management };

  updateUser = () => {
    const { updateAction } = this.props;
    updateAction({ loading: true });
    const {
      mutate,
      onNext,
      year_career_began,
      number_of_clients,
      avg_client_age,
      assets_under_management,
      annual_revenue,
      recurring_revenue,
      percent_commission,
      percent_fee_based,
      transition_goals,
      years_practicing,
      fee_based_revenue,
      trailer_revenue,
      direct_revenue,
      transactional_revenue,
      locale,
      planning_preference,
    } = this.props;

    mutate({
      variables: {
        year_career_began,
        years_practicing,
        number_of_clients,
        avg_client_age: avg_client_age || 45,
        assets_under_management: assets_under_management || 10000000,
        annual_revenue,
        recurring_revenue,
        percent_commission: percent_commission * 100,
        percent_fee_based: percent_fee_based * 100,
        transition_goals,
        planning_preference,
        fee_based_revenue,
        trailer_revenue,
        direct_revenue,
        transactional_revenue,
        locale,
      },
    })
      .then(response => {
        onNext(STEP_NUMBERS.productMix);
        updateAction({ loading: false });
      })
      .catch(error => {
        this.setState({ error });
        updateAction({ loading: false });
        // eslint-disable-next-line
        console.log('there was an error sending the query', error);
      });
  };

  validate = () => {
    const {
      year_career_began,
      number_of_clients,
      annual_revenue,
      recurring_revenue,
      percent_commission,
      percent_fee_based,
      transition_goals,
      assets_under_management,
      percent_revenue_recurring,
      percent_non_recurring_revenue,
      fee_based_revenue,
      trailer_revenue,
      direct_revenue,
      transactional_revenue,
      alterItems,
      showRequestAumOnSutd,
      user,
    } = this.props;
    if (user.is_student && user.company?.enabled_student_profiles) {
      return true;
    }
    if (alterItems) {
      return (
        year_career_began > 0 &&
        number_of_clients > 0 &&
        number_of_clients <= 4000 &&
        (fee_based_revenue > 0 || trailer_revenue > 0 || direct_revenue > 0 || transactional_revenue > 0) &&
        transition_goals.length > 0
      );
    }

    let aumOk = true;
    if (showRequestAumOnSutd && assets_under_management == null) {
      aumOk = false;
    }

    return (
      year_career_began > 0 &&
      number_of_clients > 0 &&
      annual_revenue + recurring_revenue > 0 &&
      this.validRevenue() &&
      this.validRecRevenue() &&
      transition_goals.length > 0 &&
      aumOk
    );
  };

  formatCompensationLabel = (v, t) => {
    const { intl } = this.props;
    if (v == 0 || v == null) return '0' + intl.formatMessage({ id: `sign_up_pages.percent` });
    if (v == 100) return '100' + intl.formatMessage({ id: `sign_up_pages.percent` });
    return `${v}` + intl.formatMessage({ id: `sign_up_pages.percent` });
  };

  toggleGoal = e => {
    let { transition_goals, user } = this.props;
    const { updateAction } = this.props;
    const userMotivation = getMotivation(transition_goals);
    const goal = e.target.value;

    if (userMotivation === getMotivation([goal]) || !get(user, 'company.is_managed')) {
      const index = findIndex(transition_goals, o => o === e.target.value);
      transition_goals = [...transition_goals];
      if (index > -1) {
        transition_goals.splice(index, 1);
      } else {
        transition_goals.push(e.target.value);
      }
      updateAction({ transition_goals });
    } else {
      updateAction({ transition_goals: [e.target.value] });
    }

    // if (get(user, 'company.is_managed')) {
    //   updateAction({ transition_goals: [e.target.value] });
    // } else {
    //   const index = findIndex(transition_goals, o => o === e.target.value);
    //   transition_goals = [...transition_goals];
    //   if (index > -1) {
    //     transition_goals.splice(index, 1);
    //   } else {
    //     transition_goals.push(e.target.value);
    //   }
    //   updateAction({ transition_goals });
    // }
  };

  validRevenue = () => {
    const { annual_revenue } = this.props;
    return annual_revenue >= 1;
  };

  validRecRevenue = () => {
    const { annual_revenue, recurring_revenue } = this.props;
    return recurring_revenue <= annual_revenue;
  };

  validateNumberOfClients = () => {
    const { number_of_clients, alterItems } = this.props;
    if (alterItems) {
      return number_of_clients < 1 || number_of_clients === null || isNaN(number_of_clients);
    }
    return number_of_clients && number_of_clients < 10;
  };

  debouncedUpdate = debounce(args => {
    const { updateAction } = this.props;
    updateAction(args);
  }, 1000);

  sliderChange = args => {
    this.setState(args);
    this.debouncedUpdate(args);
  };

  render() {
    const {
      updateAction,
      year_career_began,
      years_practicing,
      number_of_clients,
      annual_revenue,
      percent_commission,
      percent_fee_based,
      percent_revenue_recurring,
      percent_non_recurring_revenue,
      transition_goals,
      modalTitle,
      modalSubtitle,
      intl,
      alterItems,
      classes,
      stylesheetName,
      recognition_level,
      disableRevenueSliders,
      showPlanningQuestion,
      showRequestAumOnSutd,
      assets_under_management,
      company,
      user,
      theme,
    } = this.props;
    const { error, avg_client_age } = this.state;
    const recurring_revenue =
      annual_revenue <= this.props.recurring_revenue ? annual_revenue : this.props.recurring_revenue;
    const isStudent = get(user, "is_student") && get(user, "company.enabled_student_profiles");

    return (
      <div className="content">
        {!isStudent && (
          <p>
            <h2>{modalTitle}</h2>
            <p>{modalSubtitle}</p>
            <WarningText visible={error} message={get(this.state, 'error.message', '').replace('GraphQL error:', '')} />
            <div onMouseOver={e => spotlightElement('.practice-info')} style={{ marginBottom: 50 }}>
              <YearsInBusinessDropDown
                year_career_began={year_career_began}
                updateAction={updateAction}
                years_practicing={years_practicing}
                imported_production_data_read_only={get(user, 'company.imported_production_data_read_only')}
              />
              <br />
              <b>
                <FormattedMessage id="sign_up_pages.how_many_clients" />
              </b>
              {!alterItems && (
                <WarningText
                  visible={!alterItems && number_of_clients && number_of_clients < 10}
                  message={intl.formatMessage({
                    id: 'sign_up_pages.clients_are_you_sure',
                  })}
                />
              )}
              {alterItems && (
                <div>
                  {this.validateNumberOfClients() && <div style={{ fontSize: '10px' }}>(<FormattedMessage id="sign_up_pages.must_be_greater_than" />)</div>}
                  <WarningText
                    visible={number_of_clients > 4000}
                    message={<FormattedMessage id="sign_up_pages.the_number_of_clients_must_be" />}
                  />
                </div>
              )}
              <input
                className="form-control"
                label="How many clients do you have?"
                value={number_of_clients}
                defaultValue={0}
                onChange={e => updateAction({ number_of_clients: parseInt(e.target.value, 10) })}
                type="number"
                min={1}
                max={4000}
                shouldFitContainer
                isLabelHidden
                isInvalid={this.validateNumberOfClients()}
                error={number_of_clients > 4000}
                readOnly={get(user, 'company.imported_production_data_read_only')}
              />
              <br />
              <RangeSliderContainer theme={theme}>
                <InputSlider
                  title={intl.formatMessage({
                    id: 'sign_up_pages.what_is_average_age_of_clients',
                  })}
                  selector=".practice-info"
                  maxValue={75}
                  minValue={20}
                  step={1}
                  value={avg_client_age || 45}
                  onChange={v => this.sliderChange({ avg_client_age: v })}
                />
              </RangeSliderContainer>
              {showRequestAumOnSutd && (
                <Grid container spacing={1}>
                  <Grid
                    item
                    xs={9}
                    style={{marginTop: 0}}>
                    <span style={{ fontWeight: 'bold' }}>
                      What is your total Assets Under Management?
                    </span>
                  </Grid>
                    <Grid item xs={7}>
                      <RangeSliderContainer theme={theme}>
                        <InputSlider
                          maxValue={1000000000}
                          minValue={0}
                          value={assets_under_management}
                          formatLabel={value => ``}
                          step={1000000}
                          onChange={e => {
                            updateAction({
                              assets_under_management: parseFloat(e),
                            });
                          }}
                        />
                      </RangeSliderContainer>
                    </Grid>
                  <Grid item xs={5}>
                    <CurrencyInput
                      name="user[assets_under_management]"
                      id="outlined-name"
                      value={assets_under_management}
                      intlConfig={intlCurrencyConfig(intl)}
                      allowDecimals={false}
                      onValueChange={(value, name) => {updateAction({assets_under_management: parseFloat(value)})}}
                    />
                  </Grid>
                  <WarningText
                    visible={assets_under_management > 1000000000}
                    message={intl.formatMessage({ id: 'valuation_drawer.summary_value_warning' })}
                  />
                </Grid>
              )}
            </div>
            {alterItems &&
              recognition_level &&
              recognition_level !== 'NULL' && (
                <div>
                  <b>Recognition Level:</b>
                  <p>{recognition_level}</p>
                </div>
              )}
            {alterItems && (
              <div className={classes.productionSummary}>
                <b>{intl.formatMessage({ id: 'sign_up_pages.production_summary', })}</b>
                <p>
                  {intl.formatMessage({ id: 'sign_up_pages.the_following', })}
                  {`${!disableRevenueSliders && intl.locale == 'en'
                    ? 'If this needs to be adjusted, please use the sliding scale or input boxes below.'
                    : ''}`}
                </p>
                <PeProductSummaryReportContainer
                  stylesheetName={stylesheetName}
                  classes={classes}
                  alterItems
                  updateAction={updateAction}
                  disableRevenueSliders={disableRevenueSliders}
                />
              </div>
            )}
            {!alterItems && (
              <div>
                <div onMouseOver={e => spotlightElement('.compensation-mix')}>
                  <RangeSliderContainer theme={theme}>
                    <InputSlider
                      title={intl.formatMessage({
                        id: 'sign_up_pages.what_portion_of_your_income',
                      })}
                      selector=".compensation-mix"
                      maxValue={100}
                      minValue={0}
                      step={1}
                      value={Math.round(percent_commission * 100) || 0}
                      formatLabel={this.formatCompensationLabel}
                      onChange={v =>
                        updateAction({
                          percent_commission: v / 100,
                          percent_fee_based: (100 - v) / 100,
                        })}
                    />
                  </RangeSliderContainer>
                </div>
                <div onMouseOver={e => spotlightElement('.revenue-mix')}>
                  <b>
                    <FormattedMessage id="user_details.annual_revenue" />
                  </b>
                  <p>
                    <FormattedMessage id="sign_up_pages.what_was_your_total_revenue_for_last_year" />
                  </p>
                  <WarningText
                    visible={annual_revenue && !this.validRevenue()}
                    message={intl.formatMessage({
                      id: 'sign_up_pages.providing_your_accurate_revenue',
                    })}
                  />
                  <input
                    label={intl.formatMessage({
                      id: 'user_details.annual_revenue',
                    })}
                    value={annual_revenue}
                    onChange={e => {
                      updateAction({
                        annual_revenue: parseFloat(sanitizeNumber(e.target.value)),
                        percent_revenue_recurring: recurring_revenue / parseFloat(sanitizeNumber(e.target.value)),
                        percent_non_recurring_revenue: 1 - recurring_revenue / parseFloat(sanitizeNumber(e.target.value)),
                      });
                    }}
                    min={1000}
                    max="2147483647"
                    shouldFitContainer
                    isLabelHidden
                    type="number"
                    isInvalid={annual_revenue && !this.validRevenue()}
                    className="form-control currency"
                  />
                  <br />
                  <b>
                    <FormattedMessage id="user_details.recurring_revenue" />
                  </b>
                  <p>
                    <FormattedMessage id="sign_up_pages.how_much_of_your_annual_revenue_was_received" />
                  </p>
                  <WarningText
                    visible={recurring_revenue && !this.validRecRevenue()}
                    message={intl.formatMessage({
                      id: 'sign_up_pages.recurring_revenue_should_be_less_than',
                    })}
                  />
                  <input
                    label="Recurring Revenue"
                    value={recurring_revenue}
                    max={annual_revenue}
                    min={1000}
                    onChange={e =>
                      updateAction({
                        recurring_revenue: parseFloat(sanitizeNumber(e.target.value)),
                        percent_revenue_recurring: parseFloat(sanitizeNumber(e.target.value)) / annual_revenue,
                        percent_non_recurring_revenue: 1 - parseFloat(sanitizeNumber(e.target.value)) / annual_revenue,
                      })}
                    isInvalid={recurring_revenue && !this.validRecRevenue()}
                    shouldFitContainer
                    isLabelHidden
                    type="number"
                    className="form-control currency"
                  />
                  <br />
                </div>
              </div>
            )}
          </p>
        )}
        <b>
          <FormattedMessage id="profile_page.headline_section.advice_for_contacting_you" />
        </b>
        <p>
          <FormattedMessage
            id="profile_page.headline_section.advice_for_contacting_you_prompt"
            values={{ branded_name: get(this.props, 'user.company.branded_name', 'this service') }}
          />
        </p>
        <TransitionGoalSelector
          transition_goals={transition_goals}
          toggleGoal={this.toggleGoal}
          stylesheetName={stylesheetName}
        />
        {showPlanningQuestion && !isStudent &&  (
          <div>
            <br />
            <b>
              {intl.locale == 'en' ? get(user, 'company.catastrophic_planning_word') : 'Continuité'}</b>
            <br />
            <p>
              <FormattedMessage id="sign_up_pages.planning_question" values={{ catastrophic_planning_word: get(user, 'company.catastrophic_planning_word'), branded_name: get(company, 'branded_name', 'the system'), platform_name: get(company, 'branded_name') }} />
            </p>
            <CatastrophicPlanningPreference />
          </div>
        )}
        <p style={{ paddingTop: 25 }}>
          <FormattedMessage id="sign_up_pages.if_your_are_happy_with" />{' '}
        </p>
        <br />
        <br />
        <div className="text-center">
          <LoadingButtonWrapper stateChild="user">
            <button
              onClick={this.updateUser}
              className={'btn btn-default'}
              style={{ padding: '12px 24px', color: '#fff' }}
              disabled={!this.validate()}>
              <FormattedMessage id="sign_up_pages.next" />
            </button>
          </LoadingButtonWrapper>
        </div>
      </div>
    );
  }
}

PracticeInformation.propTypes = {
  year_career_began: PropTypes.number,
  number_of_clients: PropTypes.number,
  avg_client_age: PropTypes.number,
  assets_under_management: PropTypes.number,
  annual_revenue: PropTypes.number,
  percent_commission: PropTypes.number,
  percent_fee_based: PropTypes.number,
  recurring_revenue: PropTypes.number,
  transition_goals: PropTypes.array,
  updateAction: PropTypes.func,
  locale: PropTypes.string,
  modalTitle: PropTypes.string,
  modalSubtitle: PropTypes.string,
  mutate: PropTypes.func.isRequired,
  recognition_level: PropTypes.string,
  years_practicing: PropTypes.number,
};

PracticeInformation.defaultProps = {
  year_career_began: moment().year(),
  number_of_clients: 500,
  avg_client_age: 45,
  assets_under_management: 0,
  annual_revenue: 0,
  percent_commission: 100,
  percent_fee_based: 0,
  recurring_revenue: 0,
  transition_goals: [],
  locale: 'en',
  modalTitle: <FormattedMessage id="sign_up_pages.customize_your_profile" />,
  modalSubtitle: <FormattedMessage id="sign_up_pages.complete_your_profile_to_appear" />,
  recognition_level: '',
  years_practicing: 0,
};

const RangeSliderContainer = styled.div`
  .input-range__slider {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #006de4;
    border: 1px solid #006de4;
    border-radius: 100%;
    cursor: pointer;
    display: block;
    height: 1rem;
    margin-left: -0.5rem;
    margin-top: -0.65rem;
    outline: none;
    position: absolute;
    top: 50%;
    -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
    transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
    width: 1rem;
  }
  .input-range__slider:active {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  .input-range__slider:focus {
    box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
  }
  .input-range--disabled .input-range__slider {
    background: #cccccc;
    border: 1px solid #cccccc;
    box-shadow: none;
    -webkit-transform: none;
    transform: none;
  }

  .input-range__slider-container {
    -webkit-transition: left 0.3s ease-out;
    transition: left 0.3s ease-out;
  }

  .input-range__label {
    color: #aaaaaa;
    font-family: "Helvetica Neue", san-serif;
    font-size: 0.8rem;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    white-space: nowrap;
  }

  .input-range__label--min,
  .input-range__label--max {
    bottom: -1.4rem;
    position: absolute;
  }

  .input-range__label--min {
    left: 0;
  }

  .input-range__label--max {
    right: 0;
  }

  .input-range__label--value {
    position: absolute;
    top: -1.8rem;
  }

  .input-range__label-container {
    left: -50%;
    position: relative;
  }
  .input-range__label--max .input-range__label-container {
    left: 50%;
  }

  .input-range__track {
    background: #eeeeee;
    border-radius: 0.3rem;
    cursor: pointer;
    display: block;
    height: 0.3rem;
    position: relative;
    -webkit-transition: left 0.3s ease-out, width 0.3s ease-out;
    transition: left 0.3s ease-out, width 0.3s ease-out;
  }
  .input-range--disabled .input-range__track {
    background: #eeeeee;
  }

  .input-range__track--background {
    left: 0;
    margin-top: -0.15rem;
    position: absolute;
    right: 0;
    top: 50%;
  }

  .input-range__track--active {
    background: #006de4;
  }

  .input-range {
    height: 1rem;
    position: relative;
    width: 100%;
  }

  ${props =>
    css`
      .input-range__slider {
        background: ${props.theme.palette.accent.main} !important;
        border: 1px solid ${props.theme.palette.accent.main} !important;
      }

      .input-range__track--active {
        background: ${props.theme.palette.accent.main};
      }
    `};
`;

const mapStateToProps = state => ({ user: state.user, company: state.company });

export default withTheme(withStyles(styles)(injectIntl(connect(mapStateToProps)(PracticeInformation))));
