import React, { useState } from 'react';
import { Box, CardContent, Typography, IconButton, Paper, TextField, TextareaAutosize, Button, Grid } from '@material-ui/core';
import { Add, Save, Clear } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { useMutation, useQuery } from '@apollo/client';
import client from '../../lib/apolloClient';
import gql from 'graphql-tag';
import { get } from 'lodash';
import { S3Image } from '../../lib/s3';
import { connect } from 'react-redux';

const CREATE_TEAM_REQUEST = gql`
  mutation CreateTeamRequest($emails: [String!]!, $imageName: String!, $teamName: String, $teamHeadline: String, $teamDescription: String) {
    createTeamRequest(emails: $emails, imageName: $imageName, teamName: $teamName, teamHeadline: $teamHeadline, teamDescription: $teamDescription) {
      id
      status
      __typename
    }
  }
`;

const getCurrentUser = gql`
  query getCurrentUser {
    getCurrentUser {
      id
      company {
        id
        name
        team_name_approval_url
      }
    }
  }
`;

const TeamRequestForm = ({ user, classes, company }) => {
  const [emails, setEmails] = useState(['']);
  const [creating, setCreating] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [teamName, setTeamName] = useState('');
  const [teamHeadline, setTeamHeadline] = useState('');
  const [teamDescription, setTeamDescription] = useState('');
  const [createTeamRequest] = useMutation(CREATE_TEAM_REQUEST, { client });
  const showTeamAgreementsRequiredCopy = get(company, 'features', []).includes('team_agreements_required');
  const { data: userData } = useQuery(getCurrentUser, { client });
  const teamNameApprovalUrl = get(userData, 'getCurrentUser.company.team_name_approval_url');
  let companyName = get(userData, 'getCurrentUser.company.name', '');
  companyName = companyName ? companyName.charAt(0).toUpperCase() + companyName.slice(1) : '';
  const teamNameRequiresApproval = get(company, 'features', []).includes('team_name_requires_approval');

  const handleCreateTeamRequest = async () => {
    setCreating(true);
    createTeamRequest({ variables: { emails, imageName: selectedImage, teamName, teamHeadline, teamDescription } }).then(result => {
      setEmails(['']);
      setSelectedImage('');
      setCreating(false);
      window.location.href = '/team_requests';
    });
  };

  const allEmailsFilledOut = emails.every(email => {
    return Boolean(email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/));
  });

  const imageOptions = ['1', '2', '3'];

  return (
    <Box className={classes.root}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h5" gutterBottom>
          Invite Team Members
        </Typography>
        {showTeamAgreementsRequiredCopy ? (
          <Typography variant="body1" gutterBottom>
            On a team? Want to highlight the strength of your practice? Great, Simply enter the emails of the other advisors on your team.
            Your request will be submitted to the home office and when approved your profile will be updated to reflect that of your team.
            <br />
            <strong>NOTE: You must have an approved Team Agreement on file to have your profile enhanced to a team.</strong>
          </Typography>
        ) : (
          <Typography variant="body1" gutterBottom>
            On a team? Want to highlight the strength of your practice? Great, Simply enter the emails of the other advisors on your team.
            Your request will be submitted to the home office and when approved your profile will be updated to reflect that of your team.
          </Typography>
        )}
        <Paper className={classes.emailBox}>
          <Typography variant="h6" gutterBottom>
            {teamNameRequiresApproval 
              ? `${companyName} Approved Team Name`
              : 'Give your team a name'}
          </Typography>
          <TextField
            value={teamName}
            onChange={(e) => setTeamName(e.target.value)}
            label="Team Name"
            variant="outlined"
            fullWidth
          />
          {teamNameRequiresApproval && (
            <Typography variant="body2"> 
            Please note: You must supply an approved {companyName} Team Name. If you do not have an approved {companyName} Team Name you must complete the{' '}
            <a href={teamNameApprovalUrl} target="_blank" rel="noopener noreferrer">
              Approval Form here
            </a>
            .
          </Typography>
          )}
        </Paper>
        <Paper className={classes.emailBox}>
          <Typography variant="h6" gutterBottom>
            Add a team headline
          </Typography>
          <TextField
            value={teamHeadline}
            onChange={(e) => setTeamHeadline(e.target.value)}
            label="Team Headline"
            variant="outlined"
            fullWidth
          />
          <Typography variant="h6" gutterBottom>
            Add a team description
          </Typography>
          <TextareaAutosize
            value={teamDescription}
            shouldFitContainer
            minRows={4}
            isSpellCheckEnabled
            multiline
            key="bio"
            style={{ width: '100%' }}
            onChange={(e) => setTeamDescription(e.target.value)}
            label="Team Description"
            variant="outlined"
            fullWidth
          />
        </Paper>
        <Paper className={classes.imageBox}>
          <Typography variant="h6" gutterBottom>
            Select Team Image
          </Typography>
          <Grid container spacing={2} justifyContent="center">
            {imageOptions.map((option) => (
              <Grid item key={option}>
                <Box
                  className={`${classes.imageOption} ${selectedImage === option ? classes.selectedImage : ''}`}
                  onClick={() => setSelectedImage(option)}
                >
                  <img 
                    src={S3Image(`${company?.short_name}-${option}-team.png`)}
                    alt={`Image ${option}`}
                    className={classes.teamImage} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Paper>

        <Paper className={classes.emailBox}>
          <Typography variant="h6" gutterBottom>
            Enter the emails of your team members
          </Typography>
          {emails.map((email, index) => (
            <Box key={index}>
              <TextField
                value={email}
                className={classes.emailInput}
                onChange={(e) => setEmails([...emails.slice(0, index), e.target.value, ...emails.slice(index + 1)])}
                label={`Email #${index + 1}`} variant="outlined"
              />
              {index !== 0 && <IconButton onClick={() => setEmails([...emails.slice(0, index), ...emails.slice(index + 1)])}>
                <Clear className={classes.clear} />
              </IconButton>}
            </Box>
          ))}
          <IconButton onClick={() => setEmails([...emails, ''])}>
            <Add />
            Add Email
          </IconButton>
        </Paper>
        <Box className={classes.box}>
          <center>
            <Button
              variant="contained"
              onClick={handleCreateTeamRequest}
              disabled={!allEmailsFilledOut || creating || !selectedImage}
            >
              <Save /> &nbsp; Submit Team Request
            </Button>
            <hr className={classes.line} />
            <Typography variant="body2" gutterBottom className={classes.disclaimer}>
              By clicking "Submit Team Request" your request will be forwarded to the home office and you will be notified shortly after.
            </Typography>
          </center>
        </Box>
      </CardContent>
    </Box>
  );
}

const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  cardContent: {
    minHeight: 600,
    padding: theme.spacing(2),
  },
  messageBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  emailBox: {
    padding: theme.spacing(2),
    width: 400,
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
  imageBox: {
    padding: theme.spacing(2),
    width: 400,
    margin: 'auto',
    marginTop: theme.spacing(2),
  },
  emailInput: {
    width: '90%',
    marginTop: theme.spacing(1),
  },
  clear: {
    color: 'red',
    float: 'right',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  box: {
    marginTop: theme.spacing(2),
  },
  line: {
    width: 400,
  },
  disclaimer: {
    color: '#999',
    width: 400,
  },
  imageOption: {
    cursor: 'pointer',
    border: '2px solid transparent',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1),
    transition: 'border-color 0.3s',
  },
  selectedImage: {
    borderColor: theme.palette.primary.main,
  },
  teamImage: {
    width: 80,
    height: 80,
    borderRadius: theme.shape.borderRadius,
  },
});

const mapStateToProps = state => ({
  company: state.company,
});

export default connect(mapStateToProps)(withStyles(styles)(TeamRequestForm));