import React, {useState} from 'react';
import { 
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  FormControlLabel,
  Checkbox, } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import numeral from 'numeral';
import { reject, sortBy } from 'lodash';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/client';
import client from '../../lib/apolloClient';
import TeamRequestItemRow from './TeamRequestItemRow';
import NewTeamRequestItem from './NewTeamRequestItem';
import { Tab } from 'bootstrap';
import { tr } from 'date-fns/locale';

const APPROVE_TEAM_REQUEST = gql`
  mutation ApproveTeamRequest($id: ID!, $reason: String!, $teamAgreement: Boolean) {
    approveTeamRequest(id: $id, reason: $reason, teamAgreement: $teamAgreement) {
      id
      user {
        id
        gross_production
        assets_under_management
        number_of_clients
      }
      team {
        id
        teamAgreement
        __typename
      }
    }
  }
`;

const REJECT_TEAM_REQUEST = gql`
  mutation RejectTeamRequest($id: ID!, $reason: String!) {
    rejectTeamRequest(id: $id, reason: $reason) {
      id
    }
  }
`;

const TeamRequest = ({ teamRequest, classes }) => {
  const [approveTeamRequest] = useMutation(APPROVE_TEAM_REQUEST, {client});
  const [rejectTeamRequest] = useMutation(REJECT_TEAM_REQUEST, {client});
  const [tRequest, setTRequest] = useState(teamRequest);
  const [teamAgreement, setTeamAgreement] = useState(false);


  const handleApprove = () => {
    const reason = prompt('Please enter a reason for approving this team request');
    if(reason){
      approveTeamRequest({ 
        variables: { 
          id: teamRequest.id, 
          reason,
          team_headline: tRequest.teamHeadline,
          team_description: tRequest.teamDescription,
          teamAgreement: teamAgreementRequired ? teamAgreement : null
        } 
      }).then(({data}) => {
        window.location.href = '/admin/teams';
      });
    }
  };

  const handleReject = () => {
    const reason = prompt('Please enter a reason for rejecting this team request');

    rejectTeamRequest({ variables: { id: teamRequest.id, reason, } }).then(() => {
      window.location.href = '/admin/team_requests';
    });
  };

  const updateTeamRequestItems = ({item, newItem})  => {
    const items = reject(tRequest.teamRequestItems, {id: item.id});
    setTRequest({
      ...tRequest,
      teamRequestItems: [...items, newItem],
    });
  };

  let approvedItems = tRequest?.teamRequestItems.filter(i => i.approved) || [];
  let approvedItemsIncludingUser = approvedItems;
  approvedItemsIncludingUser = approvedItemsIncludingUser.sort((a, b) => b.email === a.email ? 1 : -1);
  const totalApprovedGrossProduction = approvedItemsIncludingUser.reduce((acc, item) => acc + item?.user?.original_gross_production, 0);
  const totalApprovedAUM = approvedItemsIncludingUser.reduce((acc, item) => acc + item?.user?.original_assets_under_management, 0);
  const totalApprovedClients = approvedItemsIncludingUser.reduce((acc, item) => acc + item?.user?.original_number_of_clients, 0);
  const teamHeadline = tRequest?.teamHeadline;
  const teamDescription = tRequest?.teamDescription;
  const teamAgreementRequired = teamRequest?.user?.company?.feature_types.includes('team_agreements_required');

  return (
    <Box sx={{ p: 2 }} style={{overflow: 'scroll', height: 'calc(100vh - 100px)'}}>
      <Box sx={{ mb: 3 }}>
        <h4>Team Request Items</h4>
        <h5>Team Headline: {teamHeadline}</h5>
        <h5>Team Description: {teamDescription}</h5>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>User found</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Status Toggle</TableCell>
              <TableCell>Catastophic Plan on File</TableCell>
              <TableCell>Did User Opt Out?</TableCell>
              <TableCell>On Another Team?</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(sortBy(tRequest?.teamRequestItems, 'email') || []).map((item) => (
              <TeamRequestItemRow key={item.id} item={item} updateTeamRequestItems={i => {
                updateTeamRequestItems({item, newItem: i});
              }}/>
            ))}
            <NewTeamRequestItem teamRequest={tRequest} updateTeamRequestItems={i => {
                updateTeamRequestItems({item: {id: null}, newItem: i});
              }}/>
          </TableBody>
        </Table>
      </Box>

      <Box sx={{ mb: 3 }}>
        <h4>Approved Team Members</h4>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Gross Production</TableCell>
              <TableCell>AUM</TableCell>
              <TableCell>Clients</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(approvedItemsIncludingUser || []).map((item, index) => (
              <TableRow key={item.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{item.user?.name}</TableCell>
                <TableCell>{numeral(item.user?.original_gross_production).format('$0,0')}</TableCell>
                <TableCell>{numeral(item.user?.original_assets_under_management).format('$0,0')}</TableCell>
                <TableCell>{item.user?.original_number_of_clients}</TableCell>
              </TableRow>
            ))}
            <TableRow className={classes.bottomRow}>
              <TableCell colSpan={2} className={classes.bottomRowCell}>Totals</TableCell>
              <TableCell className={classes.bottomRowCell}>{numeral(totalApprovedGrossProduction).format('$0,0')}</TableCell>
              <TableCell className={classes.bottomRowCell}>{numeral(totalApprovedAUM).format('$0,0')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
      <Box>
        { tRequest?.team?.id == null ?
          <p className={classes.helpText}>
          Have you approved or rejected all the team members you want to?
          Here you can approve or reject the entire request and create this team.
        </p>
        
        : <p className={classes.helpText}>
          This team has been created. To make any edits please do so in the team admin section <a href={`/admin/teams/${tRequest?.team?.id}`}>View this team</a>
        </p>}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" color="inherit" href="/admin/team_requests">Cancel</Button>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <Button 
          disabled={tRequest?.team?.id !== undefined}
          variant="contained"
          color="secondary"
          className={classes.rejected}
          onClick={handleReject}>Reject</Button>
        <Button
          disabled={approvedItems.length === 0 || tRequest?.team?.id !== undefined || (teamAgreementRequired && !teamAgreement)}
          variant="contained"
          color="primary"
          className={classes.approved}
          onClick={handleApprove}>Approve</Button>
        {teamAgreementRequired && (
          <FormControlLabel
            control={
              <Checkbox
                checked={teamAgreement}
                onChange={(e) => setTeamAgreement(e.target.checked)}
                name="teamAgreement"
                color="primary"
              />
            }
            label="Team Agreement Exists?"
          />
        )}
      </Box>
    </Box>
  );
};

const styles = theme => ({
  bottomRow: {
    borderTop: '2px solid #020202',
  },
  bottomRowCell: {
    borderBottom: 'none',
  },
  helpText: {
    fontSize: 14,
    color: '#777',
    padding: '10px',
  },
  approved: {
    background: '#8b8',
  },
  rejected: {
    color: '#fff',
    background: '#f80202',
  },
});

export default withStyles(styles)(TeamRequest);
